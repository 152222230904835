.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3); 
    animation-name: backdropFade;
    animation-duration: 0.6s;  
    overflow-y: scroll !important;
}

@keyframes backdropFade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}  
