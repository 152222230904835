.header-container header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #c00d1e;
    height: 60px;
    margin-bottom: 20px;
    z-index: 5;
}
.header-container nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 100%;
}
.logo{
    width: 140px;
    height: 37.34px;
}

@media (max-width: 550px) {
.header-container nav{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    height: 100%;
}
}