.loading-container{
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;      
    
    padding: 24% 0;
    
}

.loading-gif{
  width: 50px !important;
}


@media only screen and (max-width: 700px) {
  .loading-gif{
   margin-top: 200px;
  }
}