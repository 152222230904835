.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 20px;
  width: 240px;
  height: 85px;
  background: #fff;
  margin-left: 40px;
  padding: 10px;
  border-radius: 4px;
  top: 50px;
  box-shadow: 0px 8.63629px 25.9089px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card strong {
  display: block;
  color: #191b1c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.151135px;
}

.card span {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 12.5px;
  line-height: 18px;
  letter-spacing: -0.151135px;
  color: #191b1c;
}

#price {
  display: block;
  color: #191b1c;
  font-size: 15px;
  font-weight: bold;
}

.card_info span {
  font-weight: bold;
  font-size: 12px;
}

button.card_button:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

@media only screen and (max-width: 635px) {
  .card {
    width: 220px;
    height: 85px;
  }
}
