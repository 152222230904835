:root {
    --primary: #e63232;
    --text-on-primary: #fff;
    --background: #fff;
    --text: #333;
    --text-on-light: #000;
    --color-menu: #ddd;
    --background-menu: #222
  }
  /* @media (prefers-color-scheme: dark) {
    :root {
      --primary: purple;
      --background:  #303030;
  
    }
  } */
  /* @media (prefers-color-scheme: light) {
    :root {
      --primary: lightgreen;
      --background:  #fff;
    }
  } */
  