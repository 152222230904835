.productContainer {
  min-width: 100%;
  background: #fff;
  margin-right: 50px;
  margin-bottom: 60px;
}
.cardContent ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 30rem));
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin-top: 10px;
}

.cardContent li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cardRoundImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  width: 90px;
  height: 90px;
  margin-left: -10px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.2);
}

.cardContent {
  animation: move 750ms;
  /* animation-delay: 150ms; */
}

@keyframes move {
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 635px) {
  .productContainer {
    margin-top: -35px;
  }
}
