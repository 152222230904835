.round-container{  
    background: #fff;       
    margin-left: -10px;      
    border-radius: 50%;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.round-container{
  display: flex;    
  justify-content: center;
  align-items: center;
}

.round-content-ative img{
    border: 3px solid #c00d1e;
}
.round-content{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin: 0 15px;   
}

.round-content .round-description{
    height: 20px;
    margin-left: -10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;    
}
.round-content  span{
   font-size: 15.5px;
   font-weight: bold;
   margin-top: -6px;
   white-space: pre-line;
   text-align: center;
}

