.dashboardContainer{
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    scroll-behavior: smooth;
}

.cardapioContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-font-smoothing: antialiased !important;
    font-family: 'Roboto', sans-serif;   
    }
.cardapioContainer input, button{ 
    font: 14px 'Roboto', sans-serif;
}