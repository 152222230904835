.modal {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  position: fixed;
  top: 0;  
  z-index: 1000;
  left: 0;
  border-radius: 5px;

  animation-name: modalFade;
  animation-duration: 0.4s;
  overflow-y: initial !important;
  transition: transform 0.3s ease-out;
}

@keyframes modalFade {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modalFadeout {
  animation-name: modalFadeOut;
  animation-duration: 0.4s;
  overflow-y: initial !important;
  transition: transform 0.3s ease-out;
}

@keyframes modalFadeOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50%);
    opacity: 0;
  }
}

.modal_infotop {
  z-index: 5;
}
.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #c00d1e;
  color: #fff;
  z-index: 5;
  height: 25px;
}
.modal_header_content{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal_header h1 {
  margin: 0;
  font-size: 1.25rem;
}

.modal_footer {
  bottom: 0;
  left: 0;
  background: #930916;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 4px 4px;
  position: fixed;
  width: 100%;
}

.modal_footer span {
  color: #fff;
  margin-right: 10px;
  font-weight: bold;
}

.modal_content {
  padding: 1rem;
}

.modal_content_title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 17px;
}

.modal_content_name {
  font-size: 20px;
  font-weight: 500;
}

.modal_content_image {
  width: 80px;
  height: 80px;
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.2);
}

.modal_content_body {
  border-top: 2px solid #eee;
  white-space: pre-line;
  height: 275px;
  overflow-y: auto;
}
.modal_content_body p {
  text-align: justify;
  font-size: 15px;
  margin: 10px 10px 0px 0;
  letter-spacing: -0.151135px;
}

.modal_actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.modal_logo {
  width: 140px;
  height: 37.34px;
  margin-right: 30%;
}

.btn_modal {
  background: none;
  border: none;
  cursor: pointer;
}

div.modal_body::-webkit-scrollbar {
  width: 3px;
}

button.btn_modal:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

@media (min-width: 550px) {
  .modal {
    top: 15vh;
    height: 70%;
    width: 30rem;
    left: calc((100% - 30rem) / 2);
  }
  .modal_logo {    
    margin-right: 35%;
  }
  .modal_content_title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .modal_footer {  
    position: absolute;
  }

}
