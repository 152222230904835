.menu-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;
  padding: 30px;
  margin-top: 60px;
  height: 350px;
  
}

.menuHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menuHeader span {
  font-size: 28px;
  color: #000;
  font-weight: bold;
}
.menuHeader p {
  margin-top: 3px;
  color: #979797;
  font-size: 20px;
}

div.menuContent div {
  display: inline-block;
  text-decoration: none;
  align-items: center;
  box-sizing: border-box;
  width: 90px;
  height: 90px;
}

div.menuContent .round-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.menuContent {
  width: 55%; 
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 54px;
  padding-top: 17px;
  display: flex;
  padding-left: 30px;  
}

div.menuContent::-webkit-scrollbar {
  height: 8px;
}

/* .menuContent .round-container:hover {
  background-color: #c00d1e;
} */

.menu-search {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: -10px;
  width: 25%;
  height: 28px;
}

.menu-input {
  flex: 1;
  border: 1px solid #AFAFAF;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 0 50px 50px 0;
}

input.menu-input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.menu-button {
  background: #AFAFAF;
  border: 1px solid #AFAFAF;
  border-radius: 50px 0 0 50px;
  width: 50px;
}

button.menu-button:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.menu-button svg {
  text-align: center;
  margin-top: 4px;
}

@media only screen and (max-width: 1000px) {
  .menu-search {
    width: 65% !important;
    height: 30px !important;
  }
}

@media only screen and (max-width: 635px) {
  .menuContent {
    width: 100%;
  }
  .menuHeader span {
    font-size: 20px;
    text-align: center;
  }
  .menuHeader p {
    font-size: 15px;
    text-align: center;
  }
  div.menuContent::-webkit-scrollbar-track {
    margin-left: 30px;
    margin-right: 30px;
  }
  .menu-container {
    margin-top: -10px;
    padding-bottom: 0px;
  }
  .menuContent {
    padding-bottom: 55px;
    padding-top: 10px;
  }
  .menu-search {
    margin-top: 15px;
  }

  div.menuContent div{
    width: 80px;
    height: 80px;
  }

  div.menuContent .round-image {
    width: 80px;
    height: 80px;    
  }
  div.menuContent .round-content span {
    font-size: 14px;   
    text-align: center;
    padding-top: 10px;

  }

}

@media only screen and (max-width: 500px) {
  .menu-search {
    height: 25px !important;
  }

  .menu-button {    
    width: 45px;
  }
  .menu-button svg {
    text-align: center;
    margin-top: 2px;
    font-size: 4px !important;
  }
  .menu-input {   
    padding: 5px 5px;
    font-size: 13px;    
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
}

@media only screen and (max-width: 400px) {
  .menuHeader p {
    font-size: 16px;
    text-align: center;
  }
}

