.footer-container footer{
    position: fixed;
    width: 100%;
    bottom:0;
    left:0;
    background: #930916;
    height: 40px;    
    z-index: 5;    
}

.footer-container{
  display: none;
}
.footer-container nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem;
    height: 100%;
}


.footer-button{
    background: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.footer-event{
    color: #FFF;
    margin-right: 10px;
    font-weight: bold;
}

button.footer-button:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  

  @media only screen and (max-width: 1000px) {
    div.footer-container {
      display: block;    
    }
  }

